:root {
  color-scheme: light dark;
  --brand-color: #497b89;
  --dark-text-color: #efefef;
  --dark-secondary-text-color: #afafaf;
  --dark-bg-color: #192022;
  --dark-secondary-bg-color: #0a1113;
  --dark-lighter-bg-color: #3d3d3d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #efefef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (prefers-color-scheme: dark) {
  body {
    background: var(--dark-bg-color) !important;
    color: var(--dark-text-color) !important;
  }
}

@media screen and (min-width: 1300px) {
  .main-content {
    padding: 0 80px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  margin: 0.5rem 0;
  padding: 3px 10px;
  border-left: 0.2rem solid #497b89;
  border-bottom: 1px solid #efefef;
  position: relative;
}

@media (prefers-color-scheme: dark) {
  h2 {
    color: #efefef !important;
  }
  h2 div {
    color: #efefef;
  }
}

@media screen and (prefers-color-scheme: dark) {
  h3 {
    color: #efefef !important;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
}

.card {
  background-color: #fff;
  padding: 0.5rem;
  margin: 5px 0;
}
@media screen and (max-width: 576px) {
  .card {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (prefers-color-scheme: dark) {
  .card {
    background: var(--dark-bg-color);
    border-color: var(--dark-secondary-bg-color) !important;
  }
}

.title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #497b89;
  margin: 0.5rem 0;
  display: block;
}

h2 small {
  color: #497b89;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* ResponsiveTable */
.ant-table-row-expand-icon-cell,
.ant-table-expand-icon-th,
.ant-table-expanded-row td:empty,
.ant-list-item:empty {
  display: none;
}
.ant-table-expanded-row td {
  padding: 0px 16px !important;
}

.ant-table-thead {
  white-space: nowrap;
}

@media (prefers-color-scheme: dark) {
  .ant-tabs {
    color: #efefef !important;
  }

  .ant-table-row:hover > td {
    background: var(--dark-lighter-bg-color) !important;
  }

  .ant-table-expanded-row,
  .ant-table-expanded-row:hover {
    background: var(--dark-lighter-bg-color) !important;
  }

  .ant-table-body th {
    background: var(--dark-lighter-bg-color) !important;
  }

  .ant-table-body th span {
    color: var(--dark-text-color);
  }

  .ant-pagination-item a {
    color: var(--dark-secondary-text-color) !important;
  }

  .ant-pagination-item-active a {
    color: #000 !important;
  }

  .ant-pagination-item-ellipsis,
  .ant-pagination-item-link {
    color: var(--dark-secondary-text-color) !important;
  }

  .ant-list-item-meta-title,
  .ant-list-item-meta-description,
  .ant-timeline-item-content {
    color: var(--dark-text-color) !important;
  }

  .ant-table-footer {
    color: var(--dark-text-color) !important;
    background: var(--dark-lighter-bg-color) !important;
  }

  .ant-card {
    background: var(--dark-lighter-bg-color) !important;
    border-color: var(--dark-lighter-bg-color) !important;
    color: var(--dark-text-color) !important;
  }
}

#crisp-chatbox a {
  bottom: 70px !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

#chat-icon-div {
  bottom: 50px !important;
}

@media screen and (max-width: 900px) {
  #crisp-chatbox a {
    bottom: 95px !important;
  }
  #chat-icon-div {
    bottom: 95px !important;
  }
}

.tab-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0px 8px;
}
.tab-menu span {
  border-radius: 5px;
  width: 100%;
  color: #000;
  background: #f3f3f3;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: normal;
}
.tab-menu span.active {
  background: #497b89;
  color: #fff;
  font-weight: 500;
}
.tab-container > div {
  visibility: hidden;
  height: 0px;
}
.tab-container > div.active {
  visibility: visible;
  height: auto;
}
.more {
  cursor: pointer;
}
.button-og {
  color: #fff;
  font-size: 12px;
  background: #497b89;
  border-radius: 4px;
  padding: 3px 5px;
  outline: none;
  border: none;
  cursor: pointer;
}
.tr-1 {
  background: #f7f7f7;
}

@media (prefers-color-scheme: dark) {
  td {
    color: var(--dark-text-color);
  }

  .tr-1 {
    background: var(--dark-secondary-bg-color);
  }
}

.radio-og {
  height: 22px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #c9e5ed !important;
  border-radius: 10px !important;
}

.radio-og .ant-radio-button-wrapper {
  margin-bottom: 5px !important;
  vertical-align: middle !important;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 12px !important;
  border-style: none !important;
  border-radius: 10px !important;
}

.radio-og .ant-radio-button-wrapper:not(:first-child):not(:last-child) {
  border-width: 1px 0 1px 0 !important;
}

.radio-og .ant-radio-button-wrapper:not(:first-child)::before {
  width: 0 !important;
}

.radio-og .ant-radio-button-wrapper-checked {
  background: #c9e5ed !important;
  color: #333333 !important;
  box-shadow: 0 0 0 0 #c9e5ed !important;
  border-radius: 10px !important;
}
.radio-og .ant-radio-button-wrapper:hover {
  background: #c9e5ed !important;
}

@media (prefers-color-scheme: dark) {
  .radio-og .ant-radio-button-wrapper {
    background: transparent !important;
    color: var(--dark-text-color);
    border-color: #c9c9c9 !important;
  }
  .radio-og .ant-radio-button-wrapper-checked {
    background: #fff !important;
    border-color: #fff !important;
  }
}

@keyframes glowPulse {
  from {
    background-color: #289bff;
    border-color: #289bff;
    box-shadow: 0 0 18px #289bff;
  }
  50% {
    background-color: #1682ef;
    border-color: #1682ef;
    box-shadow: 0 0 3px #333;
  }
  to {
    background-color: #289bff;
    border-color: #289bff;
    box-shadow: 0 0 18px #289bff;
  }
}

@-webkit-keyframes glowPulse {
  from {
    background-color: #289bff;
    border-color: #289bff;
    -webkit-box-shadow: 0 0 18px #289bff;
  }
  50% {
    background-color: #1682ef;
    border-color: #1682ef;
    -webkit-box-shadow: 0 0 3px #333;
  }
  to {
    background-color: #289bff;
    border-color: #289bff;
    -webkit-box-shadow: 0 0 18px #289bff;
  }
}

@keyframes orangeGlow {
  from {
    background-color: #ff902f;
    border-color: #ff902f;
    box-shadow: 0 0 18px #ff902f;
  }
  50% {
    background-color: #f60;
    border-color: #f60;
    box-shadow: 0 0 3px #333;
  }
  to {
    background-color: #ff902f;
    border-color: #ff902f;
    box-shadow: 0 0 18px #ff902f;
  }
}

@-webkit-keyframes orangeGlow {
  from {
    background-color: #ff902f;
    border-color: #ff902f;
    -webkit-box-shadow: 0 0 18px #ff902f;
  }
  50% {
    background-color: #f60;
    border-color: #f60;
    -webkit-box-shadow: 0 0 3px #333;
  }
  to {
    background-color: #ff902f;
    border-color: #ff902f;
    -webkit-box-shadow: 0 0 18px #ff902f;
  }
}

.button-glow {
  animation-name: glowPulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  -webkit-animation-name: glowPulse;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
}

.button-glow-orange {
  animation-name: orangeGlow;
  animation-duration: 3s;
  animation-iteration-count: 10;
  -webkit-animation-name: orangeGlow;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 10;
}

.selectable-text {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.echarts-for-react {
  background: url('/images/logo-watermark.png') no-repeat center;
}

@media (prefers-color-scheme: dark) {
  .echarts-for-react {
    background: url('/images/logo-watermark-dark.png') no-repeat center;
  }
}
